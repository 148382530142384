
<template>
    <div class="stepsdemo-content">
        <Card class="py-10">
            <template v-slot:title>
              <h2 class="text-center mx-auto w-full lg:w-3/4 xl:w-1/2">
                Crear Accionante
              </h2>
            </template>
            <template v-slot:content>
              <div class="p-fluid p-2 mx-auto w-full lg:w-3/4 xl:w-1/2 flex justify-center items-center gap-4">
                <div class="p-fluid formgrid grid w-full">
                    <div class="field my-3 col-12">
                        <div class="p-float-label">
                        <InputText id="fullName" v-model="model.fullname"/>
                        <label for="fullName labelSpace">
                            Nombre completo
                        </label>
                        </div>
                        <MessageError :text="errors.fullname"/>
                    </div>
                    <div class="field my-3">
                      <div class="p-float-label">
                        <Dropdown id="name"
                                  v-model="model.docTipoId"
                                  :options="tiposDocumentos"
                                  option-label="nombre"
                                  option-value="code"
                                  :filter="true"
                                  style="min-width: 241px"
                        />
                        <label for="numeroIdentificacion">
                          Tipo identificación
                        </label>
                      </div>
                      <MessageError :text="errors.numeroIdentificacion"/>
                    </div>
                    <div class="field my-3">
                      <div class="p-float-label">
                        <InputText id="numeroIdentificacion" v-model="model.numeroIdentificacion" :disabled="disable"/>
                        <label for="numeroIdentificacion">
                          N° identificación
                        </label>
                      </div>
                      <MessageError :text="errors.numeroIdentificacion"/>
                    </div>
                    <div class="field my-3">
                      <div class="p-float-label">
                        <InputText id="telefono" v-model="model.telefono"/>
                        <label for="telefono">
                          Numero telefono
                        </label>
                      </div>
                      <MessageError :text="errors.telefono"/>
                    </div>
                    <div class="field my-3">
                      <div class="p-float-label">
                        <InputText id="correo" v-model="model.correo"/>
                        <label for="correo">
                          Correo electronico
                        </label>
                      </div>
                      <MessageError :text="errors.correo"/>
                    </div>
                    <div class="field my-3">
                      <div class="p-float-label">
                        <Dropdown v-model="model.departamentoId"
                                  class="w-full"
                                  :options="departamentos"
                                  option-label="nomDpto"
                                  option-value="codDpto"
                                  @change="getMpio"
                                  :filter="true"
                        />
                        <label for="departamentoId">
                          Departamento
                        </label>
                      </div>
                      <MessageError :text="errors.departamentoId"/>
                    </div>
                    <div class="field my-3">
                      <div class="p-float-label">
                        <dropdown
                          class="w-full"
                          :options="municipios"
                          option-label="nomMpio"
                          option-value="codMpio"
                          v-model="model.municipioId"
                          :filter="true"
                        />
                        <label for="departamentoId">
                          Municipio
                        </label>
                      </div>
                      <MessageError :text="errors.municipioId"/>
                    </div>
                    <div class="field my-3">
                      <div class="p-float-label">
                        <InputText id="fullName" v-model="model.direccion"/>
                        <label for="fullName">
                          Dirección de residencia
                        </label>
                      </div>
                      <MessageError :text="errors.direccion"/>
                    </div>
                    <div class="field my-3">
                      <div class="p-float-label">
                        <InputText id="fullName" v-model="model.representanteLegal"/>
                        <label for="fullName">
                          Nombre del representante (opcional)
                        </label>
                      </div>
                    </div>
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <div class="w-full p-2 mx-auto lg:w-3/4 xl:w-1/2 flex justify-center items-center">
                <div class="flex w-full justify-center md:justify-between px-2">
                    <Button label="Regresar" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Continuar" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
              </div>
            </template>
        </Card>
    </div>
</template>

<script>
import { computed, onActivated, ref } from 'vue'
import { object, string } from 'yup'
import { useForm, useField } from 'vee-validate'
import storeTutela from '@/apps/pharmasan/juridica/procesosJuridicos/store/tutela.store'
import storeLocalizacion from '@/apps/pharmasan/juridica/procesosJuridicos/store/localizacion.store'
import DocTipoService from '@/apps/pharmasan/juridica/procesosJuridicos/services/docTipo.service'
import * as yup from 'yup'
import { useRouter } from 'vue-router'
export default {
  name: 'DatosAccionante',
  setup () {
    /** instancias **/
    const _DocTipoService = new DocTipoService()
    /** data **/
    const tiposDocumentos = ref([])
    const disable = ref(false)
    const listLocalizaciones = computed(() => {
      return storeLocalizacion.getters._departamentos
    })
    const router = useRouter()
    /** state **/
    const validationSchema = object({
      fullname: string().nullable().required().label('Nombre completo'),
      numeroIdentificacion: string().nullable().required().label('N° identificación'),
      telefono: string().nullable().required().label('Numero de telefono'),
      correo: string().nullable().label('Correo electronico'),
      departamentoId: yup.string().required('El campo es requerido').label(''),
      municipioId: yup.string().required('El campo es requerido').nullable().label(''),
      representanteLegal: string().nullable().label('Representante legal'),
      direccion: string().nullable().required().label('Dirección')
    })
    const { errors, handleSubmit, handleReset, values: model } = useForm({
      validationSchema
    })
    useField('numeroIdentificacion')
    useField('fullname')
    useField('telefono')
    useField('correo')
    useField('departamentoId')
    useField('municipioId')
    useField('representanteLegal')
    useField('direccion')
    /** methods **/
    const nextPage = handleSubmit((values) => {
      storeTutela.commit('updateAccionante', values)
      router.push('buscar-juzgado', { formData: { ...values }, pageIndex: 1 })
    })
    const prevPage = async () => {
      disable.value = false
      router.push('buscar-accionante', { pageIndex: 1 })
      handleReset()
    }
    const getMpio = ({ value }, municipioId = null) => {
      if (!value) {
        storeLocalizacion.commit('setMunicipios', [])
      } else {
        storeLocalizacion.dispatch('getMunicipios', value).then(() => {
          model.municipioId = municipioId
        })
      }
    }
    const getLoad = async () => {
      await _DocTipoService.getAll().then(({ data }) => {
        tiposDocumentos.value = data
        const { accionante: acc } = tutela.value
        if (acc.numeroIdentificacion) {
          model.numeroIdentificacion = acc.numeroIdentificacion
          model.fullname = acc.fullname
          model.telefono = acc.telefono
          model.correo = acc.correo
          model.docTipoId = acc.docTipoId
          model.departamentoId = acc.departamentoId
          model.representanteLegal = acc.representanteLegal
          model.direccion = acc.direccion
          disable.value = true
          getMpio({ value: acc.departamentoId }, acc.municipioId)
        }
      })
    }
    /** computed **/
    const tutela = computed(() => storeTutela.getters._tutela)
    const departamentos = computed(() => storeLocalizacion.getters._departamentos)
    const municipios = computed(() => storeLocalizacion.getters._municipios)
    /** hooks **/
    onActivated(() => {
      getLoad()
      storeLocalizacion.dispatch('getDeparatamentos')
    })
    return {
      nextPage,
      errors,
      model,
      tiposDocumentos,
      listLocalizaciones,
      departamentos,
      municipios,
      getMpio,
      prevPage,
      getLoad,
      tutela,
      disable
    }
  }
}
</script>
<style>
::v-deep(.p-float-label label) {
  margin-top: -1.2rem !important;
}
</style>
